import logo from './logo.svg';
import './App.css';
import { Helmet } from 'react-helmet';

function App() {
  return (
    <div className="image-container">
      <Helmet>
        <title>EverDrive Car Rental</title>
      </Helmet>
      <div className="under-construction">
        <h1>Website Under Construction</h1>
        <p>We are working hard to bring you an amazing experience. Stay tuned!</p>
      </div>
      <img
        src="images/EverDrive_Banner.jpg"
        alt="Responsive Image"
        className="responsive-image"
      />
    </div>
  );
}

export default App;
